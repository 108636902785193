/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => {
	return (
		<Layout>
			<SEO title="Contact us" />
			<div className="relative bg-white shadow-md">
				<div className="relative max-w-full mx-auto lg:grid lg:grid-cols-5">
					<div className="bg-purple-200 py-8 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
						<div className="max-w-lg mx-auto">
							<h2 className="text-2xl leading-8 font-extrabold tracking-tight text-purple-900 sm:text-3xl sm:leading-9">
								Get in touch
							</h2>
							<p className="mt-3 text-lg leading-6 text-purple-900">
								If you any suggestions or additions for our content, or any
								other type of feedback, please reach out!
							</p>
						</div>
					</div>
					<div className="bg-white py-8 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
						<div className="max-w-lg mx-auto lg:max-w-none">
							<form
								name="lupus-contact"
								method="post"
								action="/thank-you"
								data-netlify="true"
								data-netlify-honeypot="bot-field"
							>
								{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
								<input type="hidden" name="form-name" value="lupus-contact" />
								<div className="my-2">
									<label htmlFor="full_name" className="sr-only">
										Name
									</label>
									<input
										id="full_name"
										name="Name"
										className="border-gray-200 focus:border-purple-200 shadow-sm border-2 rounded relative focus:outline-none block w-full py-2 px-4 placeholder-purple-700"
										placeholder="Full name"
									/>
								</div>
								<div className="my-2">
									<label htmlFor="email" className="sr-only">
										Email
									</label>
									<input
										id="email"
										type="email"
										name="Email"
										className="form-input border-gray-200 focus:border-purple-200 shadow-sm border-2 rounded focus:outline-none block w-full py-2 px-4 placeholder-purple-700 transition ease-in-out duration-150"
										placeholder="Email"
									/>
								</div>
								<div className="my-2">
									<label htmlFor="message" className="sr-only">
										Message
									</label>
									<textarea
										id="message"
										name="Message"
										rows="4"
										className="border-gray-200 focus:border-purple-200 shadow-sm border-2 rounded focus:outline-none block w-full py-2 px-4 placeholder-purple-700 transition ease-in-out duration-150"
										placeholder="Message"
									/>
								</div>
								<div className="">
									<span className="inline-flex">
										<button
											type="submit"
											className="inline-flex justify-center py-2 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-500 focus:outline-none focus:border-purple-700 focus:shadow-outline active:bg-purple-700"
										>
											Submit
										</button>
									</span>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Contact
